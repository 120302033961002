import { makeStyles , Grid, Typography } from "@material-ui/core"
import GroupsIcon from '@mui/icons-material/Groups';
const Nosotros = () => {
    const classes = useStyles()
    return (
        
        <Grid id='nosotros' container spacing={2}>
            <Grid container spacing={2}>
                <Grid item xs>
                
                </Grid>
                <Grid item xs={6} >
                    <br></br>
                    <Typography gutterBottom variant="h3" component="div" align="center">
                    <GroupsIcon fontSize="large"/>NOSOTROS
                    </Typography>
                </Grid>
                <Grid item xs>
                
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs>
                
                </Grid>
                <Grid item xs={6}>
                    <p>
                    "DICONS LTDA, es una empresa que presta servicios de diseño, mantenimiento y construcción de proyectos de acuerdo a las necesidades de nuestros clientes, manteniendo una estructura organizacional eficiente, que optimice los recursos necesarios para desarrollar la correcta ejecución de los proyectos."
                    </p>
                </Grid>
                <Grid item xs>
                
                </Grid>
            </Grid>
            <Grid item xs={6}>
            <h4>MISIÓN</h4>                        
            <p>
                DICONS LTDA, es una empresa que presta servicios de diseño, mantenimiento y construcción de proyectos electromecánicos y civiles de acuerdo a las necesidades de nuestros clientes, manteniendo una estructura organizacional eficiente, que optimice los recursos necesarios para desarrollar la correcta ejecución de los proyectos.    
            </p>
            </Grid>
            <Grid item xs={6}>
            <h4>VISIÓN</h4>
            <p>
            Ser una empresa sólida y estable en la prestación de servicios de ingeniería, construcción y mantenimiento industrial mediante la actualización tecnológica de nuestros equipos y personal, procurando apoyo técnico y logístico para ser competitivos y reconocidos a nivel nacional. 
            </p>
            </Grid>
            <Grid item xs={6}>
            <p>
                </p>
            </Grid>
        </Grid>
        
    )
}

export default Nosotros

const useStyles = makeStyles((theme)=>({
    root: {
       
    }
}))
