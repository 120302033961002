import * as React from 'react';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import SectorEnergetico from './SectorEnergetico';
import SectorHidrocarburos from './SectorHidrocarburos';
import SectorBebidas from './SectorBebidas';
import SectorConstruccion from './SectorConstruccion';
import SectorPublica from './SectorPublica';
import { Typography } from '@mui/material';
import ConstructionIcon from '@mui/icons-material/Construction';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function Sectores() {
  return (
    <div id='sectores'>
      <br></br>
        <br></br>
        <Typography variant="h3" component="div" gutterBottom textAlign="center"><ConstructionIcon fontSize="large"/>SECTORES</Typography>
        <br></br>
      <Stack  spacing={2}>
        <Stack direction="row" justifyContent="space-around" spacing={2}>
          <Item><SectorEnergetico />  </Item>
         <Item><SectorHidrocarburos /></Item>        
        </Stack>
        <Stack direction="row" justifyContent="space-around" spacing={2}>
          <Item><SectorBebidas />  </Item>
         <Item><SectorConstruccion /></Item>        
        </Stack>
        <Stack direction="row" justifyContent="space-around" spacing={2}>
          <Item><SectorPublica />  </Item>   
        </Stack>
      </Stack>
    </div>
  );
}