import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';

const styles = {
  inicio: {
    height: 410
  }
};

const card = (
  <React.Fragment >
    <CardMedia   
        id='inicio'      
        component="img"        
        image="inicio.jpg"
        alt="Paella dish"
        style={styles.inicio}
      />
    <CardContent>
      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        DICONS LTDA
      </Typography>
      <Typography variant="h5" component="div">
        DISEÑO, MANTENIMIENTO Y CONSTRUCCIÓN
      </Typography>
      <Typography sx={{ mb: 1.5 }} color="text.secondary">
      Es nuestro interés presentar la empresa DICONS LTDA y su portafolio de
      servicios, el cual consideramos puede suplir algunas de sus necesidades
      administrativas y operativas. 
      </Typography>
      <Typography variant="body2">
      </Typography>
    </CardContent>
    

  </React.Fragment>
);

export default function BasicCard() {
  return (
    <Box sx={{ minWidth: 275 }}>
      <Card variant="outlined">{card}</Card>
    </Box>
  );
}
