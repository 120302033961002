import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Grid } from '@material-ui/core';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';


const itemData = [
    {
      img: '17.jpg',
      title: '17',
    },
    {
        img: '18.jpg',
        title: '18',
    },
    {
        img: '19.jpg',
        title: '19',
    },
    {
        img: '20.jpg',
        title: '20',
    },
    {
        img: '21.jpg',
        title: '21',
    },
  ];
  

export default function SimplePaper() {
  return (
      <div>
          <Typography variant="h3" component="div" gutterBottom textAlign="center"><ProductionQuantityLimitsIcon fontSize="large"/>PRODUCTOS</Typography>        
          <br></br>
    <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justifyContent="center"
    style={{ minHeight: '50vh' }}
  >
  
    <Grid item xs={12}>
             
    <Typography variant="h5" component="div" gutterBottom textAlign="center">REJILLAS INDUSTRIALES</Typography>        
          <br></br>
    <ImageList sx={{ width: 500, height: 450 }} cols={1} rowHeight={300}>
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
            srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
            alt={item.title}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
    </Grid>   
 
  </Grid> 
  <br></br>
  </div>
  );
}
