import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';

export default function SimpleAccordion() {
  return (
    <div>
        <br></br>
        <br></br>
        <Typography variant="h3" component="div" gutterBottom textAlign="center"><MiscellaneousServicesIcon fontSize="large"/>SERVICIOS</Typography>
        <br></br>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>MANTENIMIENTO LOCATIVO</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Prestación de servicios de consultoría y asesorías en mantenimiento integral de instalaciones, edificaciones y campos deportivos.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>CARPINTERÍA METÁLICA</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Trabajo y elaboración de productos y estructuras de toda clase a partir de metales como el hierro, acero, cobre, latón o aluminio, entre otros. Diseño, fabricación, comercialización e instalación de una gran variedad de productos metálicos
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>CERRAMIENTO DE EXTERIORES</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Superficie que rodea espacios, delimitando, acondicionando y tapando o cerrando una zona para que no pueda pasar la luz y el aire. Además, les aporta un toque distinto a tus estancias y te permite ganar mucho más aprovechamiento.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography>CUBIERTAS METÁLICAS</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Diseño y calculo de estructuras metálicas como: puentes metálicos, cubiertas, escaleras, peldaños, mezanines, rejillas, estructuras metálicas para el transporte de carga y de pasajeros, fabricación de tanques en acero inoxidable y al carbono. 
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography>PISOS INDUSTRIALES</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Nos enfocamos en construir los mejores Pisos Industriales en el ámbito de la construcción, cumpliendo con las más altas exigencias de los controles de calidad internacionales, que regulan los pisos industriales
          </Typography>
        </AccordionDetails>
      </Accordion>     
    </div>
  );
}
