import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { makeStyles, Paper } from "@material-ui/core";
import favicon from "../images/favicon.png"
import { ButtonGroup } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home';
import GroupsIcon from '@mui/icons-material/Groups';
import InfoIcon from '@mui/icons-material/Info';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import { KeyboardArrowDown } from '@material-ui/icons';

const ResponsiveAppBar = () => {
  const classes = useStyles()

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#1976d2',
      },
    },
  });

  return (
    <ThemeProvider theme={darkTheme}>
      <AppBar >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
            >
              <Paper variant="outlined" square elevation={3} className={classes.imagenMenu}>                        
              </Paper>
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
              
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                
                  <MenuItem key="INICIO" onClick={handleCloseNavMenu}>                                                    
                    <Typography textAlign="center"><HomeIcon  /> INICIO</Typography>
                  </MenuItem>
                  <MenuItem key="NOSOTROS" onClick={handleCloseNavMenu}>                                                    
                    <Typography textAlign="center"><GroupsIcon  /> NOSOTROS</Typography>
                  </MenuItem>
                  <MenuItem key="PORTAFOLIO" onClick={handleCloseNavMenu}>                                                    
                    <Typography textAlign="center"><InfoIcon  /> PORTAFOLIO</Typography>
                  </MenuItem>
                  <MenuItem key="CONTACTO" onClick={handleCloseNavMenu}>                                                    
                    <Typography textAlign="center"><PermContactCalendarIcon  /> CONTACTO</Typography>
                  </MenuItem>                  
              </Menu>
            </Box>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
            >
              <Paper variant="outlined" square elevation={3} className={classes.imagenMenu}>                        
              </Paper>
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent:'center', minHeight:'15vh' }}>
              <ButtonGroup variant="outlined" aria-label="outlined button group" size="large">
                <Button href="#inicio"><HomeIcon fontSize="large"/>INICIO</Button>
                <Button href="#nosotros"><GroupsIcon fontSize="large"/>NOSOTROS</Button>
                <Button
                endIcon={<KeyboardArrowDown />} href="#sectores"
                ><InfoIcon fontSize="large" />PORTAFOLIO</Button>
                <Button href="#contacto"><PermContactCalendarIcon fontSize="large"/>CONTACTO</Button>
              </ButtonGroup>
            </Box>            
          </Toolbar>
        </Container>
      </AppBar>
    </ThemeProvider>
  );
};
export default ResponsiveAppBar;

const useStyles = makeStyles((theme)=>({
  imagenMenu: {
      display: "flex",
      width: "15vh",
      height: "15vh",
      backgroundImage: `url(${favicon})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      color: "#fff",
      fontWeight: "bold",
      position: "relative",        
  }
}))