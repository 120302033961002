import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

export default function SectorHidrocarburos() {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image="sector_hidrocarburos.jpg"
          alt="sector_hidrocarburos"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            SECTOR HIDROCARBUROS
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Construcción y mantenimiento. <br></br>  
            Empresas:  <br></br>
            -SUPERINTENDENCIA DE OPERACIONES CASTILLA, CHICHIMENE, CASTILLA LA NUEVA Y ACACIAS <br></br>
            -DEPARTAMENTO DEL META <br></br>
            -ECOPETROL <br></br>
            -SUPERINTENDENCIA DE OPERACIONES CAMPO RUBIALES  <br></br>
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}