import * as React from 'react';
import { Button, Card, CardContent, Grid, makeStyles, TextField, Typography } from "@material-ui/core"
import SendIcon from '@mui/icons-material/Send'
import emailjs from 'emailjs-com'
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


const Contacto = () => {
  function sendEmail(e){
    e.preventDefault();
    
    
    
    emailjs.sendForm('service_cl1pc6t','template_jx35bds',e.target,'y4e-_AhdPzYesgg0D').then(res=>{
      handleClickOpen(); 
      e.target.reset(); 
      /*alert("Se ha enviado correctamente");
      console.log(res);*/
    })
    
    

  }

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  //const classes = useStyles()  
  return (        
        <Grid id='contacto'>
          <br></br>
          <br></br>
        <Card style={{ maxWidth: 450, padding: "20px 5px", margin: "0 auto" }}>
          <CardContent>
            <Typography gutterBottom variant="h5">
            CONTÁCTENOS
          </Typography> 
            <Typography variant="body2" color="textSecondary" component="p" gutterBottom>
            Para ponerse en contacto vía correo electrónico, puede dejarnos un mensaje a través del siguiente formulario, todos los campos deben diligenciarse
          </Typography> 
            <form onSubmit={sendEmail} autoComplete="off">
              <Grid container spacing={1}>
                <Grid xs={12} sm={6} item>
                  <TextField placeholder="Ingresa tu nombre" label="Nombre" variant="outlined" id="name" name="name" fullWidth required />
                </Grid>
                <Grid xs={12} sm={6} item>
                  <TextField placeholder="Ingresa tu apellido" label="Apellido" variant="outlined" id="last_name" name="last_name" fullWidth required />
                </Grid>
                <Grid item xs={12}>
                  <TextField type="email" placeholder="Ingresa tu correo" label="Correo electrónico" variant="outlined" id="email" name="email" fullWidth required />
                </Grid>
                <Grid item xs={12}>
                  <TextField type="number" placeholder="Ingresa tu número de telefóno" label="Celular" variant="outlined" id="number_phone" name="number_phone" fullWidth required />
                </Grid>
                <Grid item xs={12}>
                  <TextField label="Mensaje" multiline rows={4} placeholder="Escribe tu mensaje aquí" variant="outlined" id="message" name="message" fullWidth required />
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary" fullWidth endIcon={<SendIcon />}>Enviar</Button>
                </Grid>

              </Grid>
            </form>
          </CardContent>
        </Card>
        <br></br>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Envío de correo electrónico
          </DialogTitle>
          <DialogContent>
            <Alert variant="outlined" severity="success">
                Correo enviado exitosamente
            </Alert>
            <br></br>
            <DialogContentText id="alert-dialog-description">
             El correo electrónico fue enviado exitosamente a info@diconsltda.com, pronto se pondran en contacto con usted. Gracias por comunicarse con nosotros.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus>
            Volver
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
  )
}

export default Contacto
/*
const useStyles = makeStyles((theme)=>({
    root: {
       
    }
}))*/