import Nosotros from './components/Nosotros';
import Sectores from './components/Sectores';
import Contacto from './components/Contacto';
import PiePagina from './components/PiePagina';
import Layout from './components/Layout';
import ResponsiveAppBar from './components/ResponsiveAppBar';
import BasicCard from './components/BasicCard';
import Divider from '@mui/material/Divider';
import SimpleAccordion from './components/SimpleAccordion';
import SimplePaper from './components/SimplePaper';


function App() {
  return (
    <Layout>
      <ResponsiveAppBar />          
      <BasicCard />  
         
      <Nosotros />
      <Divider /> 

<br></br>      
      <Sectores />
      <br></br>
      <Divider /> 
      <SimpleAccordion />   
      <Divider />
      <br></br>
      <SimplePaper />        
      <Divider />
      <Contacto />
      <Divider />
      <PiePagina />
    </Layout>
  );
}

export default App;
