import { makeStyles } from "@material-ui/core"
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import favicon from "../images/favicon.png"
import Typography from '@mui/material/Typography';

const PiePagina = () => {
    const classes = useStyles()
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      }));
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid  container>
                <Grid justifyContent="center" item xs={4} spacing={3} display="flex">
                    <Item  >
                        <Paper variant="outlined" square elevation={3} className={classes.imagenMenu}>                        
                        </Paper>
                    </Item>
                    
                </Grid>
                <Grid direction="column" item xs={4} spacing={3}  display="flex">
                    <Item>
                    <Typography variant="h6" gutterBottom>
                        ¿PORQUÉ DICONS LTDA?
                    </Typography>
                         
                    </Item>
                    <Item>
                        <Typography variant="body1" gutterBottom>
                        Las rejillas estructurales DICONS LTDA cumplen con las normas y estándares internacionales ASTM – NAAMM, garantizando a nuestros clientes una rejilla de alta resistencia, durabilidad y calidad
                        </Typography>
                            
                    </Item>
                </Grid>
                <Grid direction="column" item xs={4} spacing={3}  display="flex">
                    <Item>
                        <Typography variant="h6" gutterBottom>
                            CONTACTO
                        </Typography>
                    </Item>
                    <Item>
                        <Typography variant="body1" gutterBottom>
                            OFICINA PRINCIPAL
                            <br></br>
                            Calle 4. # 19 - 66, Oficina 101
                            <br></br>
                            Duitama, Boyacá, Colombia.
                            <br></br>
                            (+57) 320-334-5867
                            <br></br>
                            (+57) 314-453-3807
                            <br></br>
                            info@diconsltda.com                            
                        </Typography>
                    </Item>
                    
                </Grid>
            </Grid>
            <br></br>
            <Grid justifyContent="center" display="flex" container>
                    <Item>
                        <Typography variant="h6" gutterBottom>
                        DICONS LTDA 2022 - ARHM
                        </Typography>
                    </Item>
            </Grid>
        </Box>                      
    )
}

export default PiePagina

const useStyles = makeStyles((theme)=>({
    imagenMenu: {       
        width: "15vh",
        height: "15vh",
        backgroundImage: `url(${favicon})`,
        backgroundSize: "cover"      
    }
}))
